/**
 * @fileoverview This file is autogenerated - do not change it manually
 * You can update the jha-icons by running the following command in Banno Online:
 *    gulp regenerate-icons
 */
import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaIconFiElement = class extends PolymerElement {
  static get is() {
    return 'jha-icon-fi';
  }
  static get template() {
    return html`
    <style>
      :host {
        display: inline-block;
        width: 24px;
        height: 24px;
        fill: var(--jha-icon-color, var(--jha-text-base), #000000);
      }
      svg {
        width: 100%;
        height: 100%;
        display: block;
      }
    </style>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M4.5,9.75H6.75v5.5H5.5a.75.75,0,0,0,0,1.5h13a.75.75,0,0,0,0-1.5H17.25V9.75H19.5a.75.75,0,0,0,.42-1.37l-7.5-5a.75.75,0,0,0-.83,0l-7.5,5A.75.75,0,0,0,4.5,9.75Zm3.75,0h1.5v5.5H8.25Zm4.5,0v5.5h-1.5V9.75Zm3,5.5h-1.5V9.75h1.5ZM12,4.9l5,3.35H7Z"/>
    <path d="M19.5,17.75H4.5a.75.75,0,0,0,0,1.5h15a.75.75,0,0,0,0-1.5Z"/>
    </svg>
  `;
  }
};

customElements.define(window.JhaIconFiElement.is,
    window.JhaIconFiElement);
export default window.JhaIconFiElement;
