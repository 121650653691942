/**
 * @fileoverview This file is autogenerated - do not change it manually
 * You can update the jha-icons by running the following command in Banno Online:
 *    gulp regenerate-icons
 */
import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaIconCircleCheckmarkElement = class extends PolymerElement {
  static get is() {
    return 'jha-icon-circle-checkmark';
  }
  static get template() {
    return html`
    <style>
      :host {
        display: inline-block;
        width: 24px;
        height: 24px;
        fill: var(--jha-icon-color, var(--jha-text-base), #000000);
      }
      svg {
        width: 100%;
        height: 100%;
        display: block;
      }
    </style>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M12,21.75A9.75,9.75,0,1,1,21.75,12,9.76,9.76,0,0,1,12,21.75Zm0-18A8.25,8.25,0,1,0,20.25,12,8.26,8.26,0,0,0,12,3.75Z"/>
    <path d="M16,9a.75.75,0,0,0-1.06,0L10.5,13.44l-2-2a.75.75,0,0,0-1.06,1.06L10,15A.75.75,0,0,0,11,15v0h0l5-4.95A.75.75,0,0,0,16,9Z"/>
    </svg>
  `;
  }
};

customElements.define(window.JhaIconCircleCheckmarkElement.is,
    window.JhaIconCircleCheckmarkElement);
export default window.JhaIconCircleCheckmarkElement;
