import {LitElement, html, css} from 'lit-element';
import {jhaStyles} from '@banno/platform-ux-shared/styles/jha-styles.js';
import store from '@banno/platform-ux-shared/session-storage/store.js';
import '@banno/platform-ux-shared/components/polymer3/jha/buttons/jha-button.js';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-close.js';
import '@banno/platform-ux-shared/components/polymer3/searchbox/jha-search-box.js';
import '@banno/platform-ux-shared/components/polymer3/jha/lists/jha-list.js';
import '@banno/platform-ux-shared/components/polymer3/jha/lists/jha-list-item.js';
import '@banno/platform-ux-shared/components/polymer3/jha/thumbnail/jha-thumbnail.js';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-circle-checkmark.js';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-fi.js';

const styles = css`
  :host {
    display: block;
  }
  :host([isinapp]) {
    max-height: 90vh;
    display: flex;
    flex-direction: column;
  }
  header[dialog] {
    padding: 32px;
    border-bottom: 1px solid var(--jha-border-color);
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
    text-align: center;
    position: relative;
    font-size: 18px;
    font-weight: 400;
    color: var(--jha-text-dark);
  }
  jha-icon-close[dialog] {
    width: 18px;
    height: 18px;
    fill: var(--jha-color-gray-medium);
  }
  jha-button[dialog] {
    --jha-button-line-height: 12px;
    position: absolute;
    top: 30%;
    right: 20px;
    opacity: 0.6;
    transition: opacity 0.3s cubic-bezier(0.1,.5,.1,1);
    top: 21%;
  }
  jha-search-box {
    margin-top: 14px;
    margin-bottom: 0;
  }
  article[dialog] {
    max-height: calc(100vh - 300px);
    color: var(--jha-text-base);
    padding: 32px;
    overflow-y: auto;
    padding: 0 0 32px 32px;
    height: 100vh;
  }
  :host([isauth]) article[dialog] {
    height: 60vh;
  }
  jha-list-item {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  span {
    flex: 1;
  }
  [title] {
    font-size: 14px;
  }
  jha-icon-circle-checkmark {
    fill: var(--jha-color-primary);
  }
  jha-icon-fi {
    width: 36px;
    height: 36px;
    fill: var(--jha-color-gray-medium);
  }
  @media (max-width: 739px) {
    header[dialog] {
      padding: 32px 16px;
    }
    jha-button[dialog] {
      right: 12px;
    }
    article[dialog] {
      padding-left: 16px;
    }
    jha-list-item {
      --jha-list-item-horizontal-spacing: 16px;
    }
  }
`;

class JhaEnterpriseInstitutionSelector extends LitElement {
  static get styles() {
    return [jhaStyles, styles];
  }

  static get properties() {
    return {
      isInApp: {type: Boolean},
      selectedInstitution: {type: Object},
      disableContinueButton: {type: Boolean},
      userInstitutions: {type: Array},
      filteredUserInstitutions: {type: Array},
      newShortId: {type: String},
      loggedInUser: {type: Object},
      buttonLoading: {type: Boolean},
      isAuth: {type: Boolean},
    };
  }

  constructor() {
    super();
    this.selectedInstitution = store.getSelectedInstitution();
    this.disableContinueButton = !Boolean(this.selectedInstitution);
    this.userInstitutions = store.institutions || [];
    this.loggedInUser = store.user;
    this.filteredUserInstitutions = this.userInstitutions;
    this.buttonLoading = false;
  }

  connectedCallback() {
    super.connectedCallback();
    if (this.isAuth && this.selectedInstitution) {
      this.newShortId = this.selectedInstitution.institution.shortId;
    }
  }

  getUserInstitutions(userInstitutions) {
    if (!userInstitutions) {
      return [];
    }
    return userInstitutions.sort((a, b) => {
      const nameA = a.institution.name.toUpperCase();
      const nameB = b.institution.name.toUpperCase();
      return nameA < nameB ? -1 : (nameA > nameB ? 1 : 0);
    });
  }

  close() {
    this.dispatchEvent(new CustomEvent('close-institution-selector', {
      bubbles: true,
      composed: true
    }));
  }

  searchList(evt) {
    // TODO: call searchList at an interval instead of every input change for better performance
    const searchValue = evt.detail.toLowerCase();
    if (searchValue) {
      this.filteredUserInstitutions = this.userInstitutions.filter(institution => {
        const nameMatch = institution.institution.name.toLowerCase().indexOf(searchValue) >= 0;
        const urlMatch = institution.institution.url.toLowerCase().indexOf(searchValue.replace(/\s/g, '')) >= 0;
        return nameMatch || urlMatch;
      });
    } else {
      this.filteredUserInstitutions = this.userInstitutions;
    }
  }

  setNewInstitution(evt) {
    if (!evt.target.id) {
      return;
    }
    this.newShortId = evt.target.id;
    this.disableContinueButton = false;
    this.changeInstitution();
  }

  showCheckmark(selectedInstitution, newShortId, itemShortId) {
    if (newShortId) {
      return newShortId === itemShortId.toString();
    }
    if (selectedInstitution) {
      return selectedInstitution.institution.shortId === itemShortId;
    }
  }

  async changeInstitution() {
    if (this.newShortId) {
      const oldShortId = this.selectedInstitution && this.selectedInstitution.institution.shortId;
      await this.setSelectedInstitutionDetails();
      sessionStorage.removeItem('cms-domain');
      sessionStorage.removeItem('cms-default-route');
      if (this.isAuth) {
        window.location = `/a/${this.newShortId}`;
      } else {
        if (oldShortId && window.location.pathname.includes(`/${oldShortId}`)) {
          // If switching institutions while in the cms we need to go back to main cms landing page.
          if (window.location.pathname.includes(`/cms/${oldShortId}`)) {
            window.location = `/a/cms/${this.newShortId}`
          } else {
            // strip out any UUID and everything after from the URL
            const pathParts = window.location.pathname.split(`/${oldShortId}`);
            let reconstructedPath = `${pathParts[0]}${this._concatSlash(pathParts[0])}${this.newShortId}`;
            for (const pathPart of (pathParts[1] || '').split('/')) {
              if (this._isUUID(pathPart)) break;
              if (pathPart.length > 0) reconstructedPath += '/' + pathPart;
            }
            window.location = reconstructedPath;
          }
        } else {
          window.location = `${window.location.href}${this._concatSlash(window.location.href)}${this.newShortId}`;
        }
      }
    }
    this.close();
  }

  _concatSlash(path) {
    return path.charAt(path.length-1) === '/' ? '' : '/';
  }


  _isUUID(value) {
    return value.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i);
  }

  async setSelectedInstitutionDetails() {
    this.buttonLoading = true;
    store.selectedInstitution = this.newShortId;
    this.selectedInstitution = store.getSelectedInstitution();
  }

  render() {
    return html`
    <header dialog>
      <div>Institutions</div>
      ${this.selectedInstitution ? html `
        <jha-button dialog sync="" icon="" @click="${this.close}">
          <jha-icon-close dialog></jha-icon-close>
        </jha-button>
      ` : null}
      <jha-search-box placeholder="Search" @jha-search="${this.searchList}" autofocus="true"></jha-search-box>
    </header>
    <article dialog>
      <jha-list collapse="">
        ${this.getUserInstitutions(this.filteredUserInstitutions).map(institution => html`
          <jha-list-item inset @click="${this.setNewInstitution}" id="${institution.institution.shortId}">
            <jha-thumbnail small>
            ${this.loggedInUser.internal ? html`
              <jha-icon-fi></jha-icon-fi>
            ` : html`
              <img src="/a/consumer/api/institutions/${institution.institution.institutionId}/icon" alt="${institution.institution.name}">
            `}
            </jha-thumbnail>
            <span>
              <div id="${institution.institution.shortId}" title class="ellipsis">${institution.institution.name}</div>
              <div id="${institution.institution.shortId}" sub-title class="ellipsis">${institution.institution.url}</div>
            </span>
            ${this.showCheckmark(this.selectedInstitution, this.newShortId, institution.institution.shortId) ? html`
              <jha-icon-circle-checkmark id="${institution.institution.shortId}"></jha-icon-circle-checkmark>
            ` : null}
          </jha-list-item>
        `)}
      </jha-list>
    </article>
    `;
  }
}

export {styles as JhaEnterpriseInstitutionSelectorStyles};

customElements.define('jha-enterprise-institution-selector', JhaEnterpriseInstitutionSelector); // eslint-disable-line @banno/ux/custom-elements-define
export default JhaEnterpriseInstitutionSelector;
