import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaListItemElement = class extends PolymerElement {
  static get is() {
    return 'jha-list-item';
  }
  static get properties() {
    return {
      role: {
        type: String,
        value: 'listitem',
        reflectToAttribute: true,
      },
    };
  }

  static get template() {
    return html`
    <style>
      :host {
        display: block;
        position: relative;
        padding-top: var(--jha-list-item-vertical-spacing, 16px);
        padding-bottom: var(--jha-list-item-vertical-spacing, 16px);
        padding-right: var(--jha-list-item-horizontal-spacing, 24px);
        padding-left: var(--jha-list-item-horizontal-spacing, 24px);
        contain: layout;
      }
      :host::after {
        clear: both;
        content: '';
        display: var(--jha-list-item-border-display, block);
        position: absolute;
        top: auto;
        bottom: 0;
        left: var(--jha-list-item-horizontal-spacing, 24px);
        right: 0;
        border-bottom: 1px var(--jha-list-item-border-style, solid) var(--jha-list-item-bottom-border, var(--jha-border-color, #ced6df));
      }
      :host([sub-title]) {
        color: var(--jha-color-neutral, #8d99a0);
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 4px;
      }
      :host([sub-title])::after {
        display: block;
      }
      :host([no-padding]) {
        padding: 0;
      }
      @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 192dpi) {
        :host::after {
          transform-origin: 0 100%;
          transform: scaleY(0.7);
        }
      }
      :host(:last-of-type)::after {
        display: var(--last-item-border-display, none);
      }
      :host(.is-active),
      :host(.active) {
        color: var(--jha-color-primary, #3aaeda);
        font-weight: 500;
      }
      :host([inset])::after {
        left: var(--jha-list-item-inset, 52px);
      }
      @media print {
        :host::after {
          border-bottom-color: #000;
        }
      }
    </style>

    <slot></slot>

  `;
  }
};

customElements.define(window.JhaListItemElement.is, window.JhaListItemElement);
export default window.JhaListItemElement;
