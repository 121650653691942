import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaListElement = class extends PolymerElement {
  static get is() {
    return 'jha-list';
  }
  static get properties() {
    return {
      role: {
        type: String,
        value: 'list',
        reflectToAttribute: true,
      },
    };
  }

  static get template() {
    return html`
    <style>
      :host {
        display: block;
        margin-bottom: 6px;
        --jha-list-item-border-display: block;
        --last-item-border-display: none;
      }
      :host ::slotted(jha-list-item:last-of-type) {
        --jha-list-item-border-display: none;
        background-image: none;
        border-bottom: none;
      }
      /* needed for IE */
      :host ::slotted(jha-list-item:last-of-type)::after {
        display: none;
      }
      :host([last-item-border]),
      :host([last-item-border]) ::slotted(jha-list-item:last-of-type)::after {
        display: block;
      }
      :host([borderless]) ::slotted(jha-list-item) {
        --jha-list-item-border-display: none;
        padding: 7px 0px;
      }
      :host([borderless]) ::slotted(jha-list-item[no-padding]) {
        padding: 0px;
      }
      /* needed for IE */
      :host([borderless]) ::slotted(jha-list-item)::after {
        display: none;
      }
      :host([collapse]) ::slotted(jha-list-item) {
        margin-left: 0;
        padding-left: 0;
      }
      @media (min-width: 740px) and (max-width: 991px) {
        :host([last-item-border]) ::slotted(jha-list-item:last-of-type)::after {
          left: var(--jha-list-item-horizontal-spacing, 16px);
        }
      }
      @media (max-width: 479px) {
        :host([borderless]) ::slotted(jha-list-item) {
          --jha-list-item-horizontal-spacing: 0;
        }
        :host([last-item-border]) ::slotted(jha-list-item:last-of-type)::after {
          left: var(--jha-list-item-horizontal-spacing, 16px);
        }
      }
    </style>

    <slot></slot>

  `;
  }
};

customElements.define(window.JhaListElement.is, window.JhaListElement);
export default window.JhaListElement;
