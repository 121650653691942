import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
import {DomIf} from '@polymer/polymer/lib/elements/dom-if.js';
import '@banno/platform-ux-shared/components/polymer3/jha/buttons/jha-button.js';
import('@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-circle-close.js');
import('../jha/icons/jha-icon-search.js');
/* global Polymer */

/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaSearchBoxElement = class extends PolymerElement {
  static get is() {
    return 'jha-search-box';
  }

  static get properties() {
    return {
      /** @type {boolean} */
      autofocus: {
        type: Boolean,
        value: false,
        observer: window.JhaSearchBoxElement.prototype._updateAutofocus,
      },
      debounceInterval: {
        type: Number,
        value: 0,
      },
      placeholder: String,
      searchValue: {
        type: String,
        value: '',
      },
    };
  }

  constructor() {
    super();
    this.debounceSearch_ = null;
  }

  connectedCallback() {
    super.connectedCallback();
    setTimeout(() => {
      this.setAttribute('role', 'textbox');
    });
  }

  blur() {
    this.$.searchInput.blur();
  }

  clear(e) {
    this.searchValue = '';
    this.update(e);
    this._fireSearchEnter(e);
  }

  focus() {
    this.$.searchInput.focus();
  }
  // This is used to pick up the click on the "clear" button in chrome.
  search(e) {
    if (!this.$.searchInput.value) {
      this.update(e);
      this._fireSearchEnter(e);
    }
  }

  update(e) {
    if (this.debounceSearch_) {
      clearTimeout(this.debounceSearch_);
      this.debounceSearch_ = null;
    }
    const input = e.target;
    this.debounceSearch_ = setTimeout(() => {
      this.dispatchEvent(
          new CustomEvent('jha-search',
              {
                bubbles: true,
                composed: true,
                detail: input.value || '',
              },
          ),
      );
    }, this.debounceInterval || 0);
  }

  _handleKeyDown(e) {
    if (e.keyCode === 13) {
      this._fireSearchEnter(e);
    }
  }

  _handleKeyUp(e) {
    this.dispatchEvent(new CustomEvent('jha-search-keyup', {
      bubbles: true,
      composed: true,
      detail: e.target.value,
    }));
  }

  _fireSearchEnter(e) {
    e.preventDefault();
    this.dispatchEvent(
        new CustomEvent('jha-search-enter',
            {
              bubbles: true,
              composed: true,
              detail: e.target.value,
            },
        ),
    );
  }

  _updateAutofocus(autofocus) {
    if (autofocus) this.focus();
  }

  showCancel(searchInputLength) {
    return Boolean(searchInputLength);
  }

  static get template() {
    return html`
    <style>
      :host {
        display: block;
        height: var(--jha-search-box-size, 36px);
        font-size: var(--jha-search-box-font-size, 14px);
      }
      label {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        position: relative;
        border-radius: 3px;
      }
      jha-icon-search {
        position: absolute;
        font-size: 0;
        left: 12px;
        top: 50%;
        z-index: 4;
        transform: translateY(-50%);
        height: var(--jha-search-box-icon-size, 18px);
        width: var(--jha-search-box-icon-size, 18px);
        fill: var(--jha-color-gray-medium, #707070);
      }
      jha-icon-circle-close {
        fill: var(--jha-color-gray-medium, #707070);
        height: var(--jha-search-box-icon-size, 18px);
        width: var(--jha-search-box-icon-size, 18px);
      }
      input::placeholder {
        font-size: var(--jha-input-placeholder-size, 13px);
        color: var(--jha-input-placeholder-color, var(--jha-text-base, #707070));
        opacity: 1;
      }
      :host([larger]) input::placeholder {
        font-size: 14px !important;
      }
      input {
        display: block;
        width: 100%;
        background-color: var(--jha-search-box-background, var(--jha-color-gray-lighter, #F2F2F2));
        border: 1px solid var(--jha-search-box-border, var(--jha-color-gray-light, #E6E6E6));
        color: var(--jha-text-dark, #191919);
        height: 100%;
        font-size: inherit;
        line-height: 1.5;
        padding: 8px 36px;
        border-radius: 3px;
        margin: 0;
        -webkit-transition: all .2s cubic-bezier(0.1,.5,.1,1);
        transition: all .2s cubic-bezier(0.1,.5,.1,1);
        -webkit-appearance: none;
      }
      input:focus {
        outline:none;
        border: 1px solid var(--jha-color-primary, #006ee4);
        box-shadow: 0px 0px 3px  var(--jha-color-primary, #006ee4);
      }
      :host([navbar]) {
        margin-bottom: 0;
        margin-left: auto;
        max-width: 320px;
        flex: 1 0 0;
      }
      :host([navbar]) jha-icon-search {
        fill: rgba(255,255,255,.6);
      }
      :host([navbar]) input {
        background-color: rgba(255,255,255,.16);
        border-color: transparent;
        color: #fff;
      }
      :host([navbar]) input[placeholder],
      :host([navbar]) ::placeholder {
        color: rgba(255,255,255,.6);
      }
      :host([no-icon]) jha-icon-search {
        display: none;
      }
      :host([no-icon]) input {
        padding-left: 8px;
      }
      /* ::slotted(jha-search-box) {

      } */
      :host([large]) {
        --jha-search-box-size: 56px;
        --jha-search-box-font-size: 16px;
        --jha-search-box-icon-size: 24px;
      }
      :host([large]) input {
        padding-left: 44px;
      }
      :host([large]) input::placeholder {
        font-size: 16px;
      }
      /* clears the ‘X’ from Internet Explorer */
      input[type=search]::-ms-clear {
        display: none;
        width : 0;
        height: 0; 
      }
      input[type=search]::-ms-reveal {
        display: none;
        width : 0;
        height: 0;
      }
      /* clears the ‘X’ from Chrome */
      input[type="search"]::-webkit-search-decoration,
      input[type="search"]::-webkit-search-cancel-button,
      input[type="search"]::-webkit-search-results-button,
      input[type="search"]::-webkit-search-results-decoration {
        display: none;
      }
      #clear {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
      }
    </style>

    <label>
      <jha-icon-search></jha-icon-search>
      <!-- eslint-disable @banno/ux/no-auto-binding -->
      <input
        aria-invalid="false"
        autofocus="[[autofocus]]"
        id="searchInput"
        on-input="update"
        on-keydown="_handleKeyDown"
        on-keyup="_handleKeyUp"
        on-search="search"
        placeholder="[[placeholder]]"
        tabindex="0"
        type="search"
        value="{{searchValue::input}}">
      <!-- eslint-enable @banno/ux/no-auto-binding -->
      <template is="dom-if" if="[[showCancel(searchValue.length)]]">
        <jha-button type="button" icon="" sync="" on-click="clear" id="clear">
          <jha-icon-circle-close aria-label="Cancel"></jha-icon-circle-close>
        </jha-button>
      </template>
    </label>
  `;
  }
};

customElements.define(window.JhaSearchBoxElement.is, window.JhaSearchBoxElement);
export default window.JhaSearchBoxElement;
